<template>
  <div class="table-header">
    <div>
      <span>建设项目</span>
    </div>

    <div class="theader-right">
      <div class="wrap-iv">
        <el-input v-model.trim="xmmc" placeholder="请输入项目名称" />
      </div>
      <el-button @click="search" size="medium">查询</el-button>
      <el-button @click="reset" style="margin-left: 0px" size="medium"
        >重置</el-button
      >
    </div>
  </div>
  <PublicTable ref="publicTable" @expand-change="exChange" :showChild="true">
    <template #expand-child>
      <el-tree
        v-loading="loading"
        element-loading-background="white"
        default-expand-all
        :data="data"
        :props="defaultProps"
        @node-click="navToInfo"
      >
        <template #default="{ node, data }">
          <span :class="{ clickable: node.isLeaf && data.Id && data.Fid }">
            <span>{{ node.label }}</span>
          </span>
        </template>
      </el-tree>
    </template>
  </PublicTable>
</template>

<script>
import PublicTable from "@/components/table/PublicTable.vue";
export default {
  components: {
    PublicTable,
  },
  data() {
    return {
      xmmc: "",
      loading: false,
      data: [],
      defaultProps: {
        children: "Children",
        label: "Name",
      },
      expandedRow: null,
    };
  },
  methods: {
    search() {
      const conditions = [];
      conditions.push({
        Key: "xmmc",
        Value: this.xmmc ? "%" + this.xmmc + "%" : "",
        IsCondition: true,
        Operator: "like",
        LogicalOperator: "and",
      });
      this.$refs.publicTable.getLoadData("jsxm", conditions);
    },
    getJSXM(Id) {
      return this.$ajax
        .get(
          this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/GetJSXMByJSXMID?JSXMID=" +
            Id
        )
        .then((response) => {
          if (response.data.IsSuccess) {
            this.data = response.data.Data;
          } else {
            throw response.data.ErrorMessage;
          }
        });
    },
    reset() {
      this.xmmc = "";
      this.search();
    },
    async exChange(row, expandedRows) {
      if (expandedRows.length > 1) {
        this.$refs.publicTable.toggleRowExpansion(expandedRows[0]);
      }
      if (row.Id != this.expandedRow?.Id) {
        try {
          this.loading = true;
          await this.getJSXM(row.Id);
        } catch (error) {
          console.log(error);
          this.data = [];
        } finally {
          this.expandedRow = row;
          this.loading = false;
        }
      }
    },
    navToInfo(data, node) {
      if (node.isLeaf && data.Id && data.Fid) {
        const rowData = JSON.stringify({
          Id: data.Id,
          SBLX: data.Fid,
          ZBH: data.Name,
        });
        this.$router.push({ path: '/bjsx/info', query: { row: rowData } })
      }
    },
  },
  activated() {
    this.search();
  },
};
</script>

<style scoped>
.table-header {
  padding: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrap-iv {
  display: inline-block;
  margin-right: 5px;
}

.clickable {
  color: #3c8dbc;
}

.clickable:hover {
  text-decoration: underline;
}
</style>